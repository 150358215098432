import React, { Component } from 'react';

import { DropDownList } from '@progress/kendo-react-dropdowns';
import { DateTimePicker } from '@progress/kendo-react-dateinputs'
import '@progress/kendo-react-intl'
import '@progress/kendo-react-tooltip'
import '@progress/kendo-react-common'
import '@progress/kendo-react-popup'
import '@progress/kendo-date-math'
import '@progress/kendo-react-dropdowns'
import axios from 'axios';
import '@progress/kendo-theme-default/dist/all.css';
import SMIGrid from '../components/SMIGrid/SMIGrid';
import * as GridCustomFilters from '../components/SMIGrid/GridCustomFilters';
import * as GridCustomTemplates from '../components/SMIGrid/GridCustomTemplates';
import { GridColumn as Column } from '@progress/kendo-react-grid';
import PageWrapper from '../components/PageWrapper/PageWrapper';
import './ViewVehicleHistory.css'

export class ViewVehicleHistory extends Component {

    maxDate = new Date();
    shortDateFormat = "yyyy-MM-dd";
    shortTimeFormat = "HH:mm:ss tt";


    constructor(props) {
        super(props);
        this.getData = this.getData.bind(this);
    }


    state = {
        connectionStringList: [],
        selectedConnectionString: {},
        vehicleIDList: [],
        selectedVehicleID: '',
        vehicleHistoryData: [],
        selectedFromDate: this.maxDate,
        selectedEndDate: this.maxDate,
        isHidden: true,
        isLoading: false,
        customConnectionString : ''
    }

    handleFromDateChnage = (event) => {
        this.setState({
            selectedFromDate: event.target.value
        });
        console.log(this.state.selectedConnectionString.value);
        console.log(this.state.customConnectionString);
        if (this.state.selectedConnectionString && this.state.selectedConnectionString.value !== "" && this.state.selectedConnectionString.value !== undefined) {
            this.getVehicleIDList(encodeURI(this.state.selectedConnectionString.value), event.target.value, this.state.selectedEndDate);
        }
        else if (this.state.customConnectionString && this.state.customConnectionString !== "") {
            this.getVehicleIDList(encodeURI(this.state.customConnectionString), event.target.value, this.state.selectedEndDate);
        }

        
    }

    handleTODateChnage = (event) => {
        this.setState({
            selectedEndDate: event.target.value
        });

        console.log(this.state.selectedConnectionString.value);
        console.log(this.state.customConnectionString);

        if (this.state.selectedConnectionString && this.state.selectedConnectionString.value !== "" && this.state.selectedConnectionString.value !== undefined) {
            this.getVehicleIDList(encodeURI(this.state.selectedConnectionString.value), this.state.selectedFromDate, event.target.value);
        }
        else if (this.state.customConnectionString && this.state.customConnectionString !== "") {
            this.getVehicleIDList(encodeURI(this.state.customConnectionString), this.state.selectedFromDate, event.target.value);
        }
    }

    getConnectionString() {

        let connectionList = [];
        let customConnection = { key: 'Custom', value: '' };
        axios.get('/api/VehicleHistory/GetConnectionStrings').then((response) => {
            connectionList = response.data;
            connectionList.unshift(customConnection);
            this.setState({ connectionStringList: connectionList });
        });
    }

    getVehicleIDList(connectionString, startDateTime, endDateTime) {

        let startDate = this.state.selectedFromDate;
        let endDate = this.state.selectedEndDate;

        if (startDateTime && this.state.selectedFromDate.getTime() !== new Date(startDateTime).getTime()) {
            startDate = new Date(startDateTime);
        }

        if (endDateTime && this.state.selectedEndDate.getTime() !== new Date(endDateTime).getTime()) {
            endDate = new Date(endDateTime);
        }

        axios.get('/api/VehicleHistory/GetVehicleID', {
            params: {
                connectionString: connectionString,
                fromTime: startDate,
                toTime: endDate
            }
        }).then((response) => {
            this.setState({ vehicleIDList: response.data });
        });
    }


    componentDidMount() {
        this.getConnectionString();
        this.getVehicleIDList(encodeURI(this.state.selectedConnectionString.value), this.state.selectedFromDate, this.state.selectedEndDate);
    }

    handleChange = (event) => {
        if (event.target.textContent !== "Custom") {
            this.setState({
                selectedConnectionString: event.target.value ,customConnectionString: ""
            });
            this.getVehicleIDList(encodeURI(event.target.value.value), this.state.selectedFromDate, this.state.selectedEndDate);
        }
        else {
            this.setState({ customConnectionString: "", selectedConnectionString: {} });
        }
    }

    updateCustomConnectionString = (event) => {
        if (this.state.customConnectionString && this.state.customConnectionString !== "" && this.state.customConnectionString != undefined) {
            this.getVehicleIDList(encodeURI(this.state.customConnectionString), this.state.selectedFromDate, this.state.selectedEndDate);
        }       
    }


    handleCustomConnectionChange = (event) => {
        console.log(event.target.value);
        let customConnection = { key: 'Custom', value: event.target.value };
        this.setState({ customConnectionString: event.target.value });
        this.setState({ selectedConnectionString: customConnection });        
    }


    handleVehcleChnage = (event) => {
        this.setState({
            selectedVehicleID: event.target.value
        });
    }

    getData() {
        if (this.state.isLoading) {
            return;
        }
        else {
            this.refs.getDataBtn.setAttribute("disabled", "disabled");
            this.setState({ isLoading: true, isHidden: false })
            if (this.state.selectedConnectionString && this.state.selectedConnectionString.value !== "" && this.state.selectedConnectionString.value !== undefined) {
                this.getVehicleHistoryAPIData(this.state.selectedConnectionString.value, this.state.selectedFromDate, this.state.selectedEndDate, this.state.selectedVehicleID);
            }
            else if (this.state.customConnectionString && this.state.customConnectionString !== "") {
                this.getVehicleHistoryAPIData(this.state.customConnectionString, this.state.selectedFromDate, this.state.selectedEndDate, this.state.selectedVehicleID);
            }

        }
        
    }

    updateConnectionString() {
        if (this.state.selectedConnectionString.key === "Custom") {
            
        }
    }

    getVehicleHistoryAPIData(connectionString, startDateTime, endDateTime, vehicleID) {
        axios.get('/api/VehicleHistory/GetVehicleHistory', {
            params: {
                connectionString: connectionString,
                fromTime: startDateTime,
                endTime: endDateTime,
                vehicleID: vehicleID
            }
        }).then((response) => {

            var result = response.data;

            result.forEach((a) => {
                for (let key in a) {
                    if (key === "fixTaken") {
                        a[key] = new Date(a[key]);
                    }
                    else if (key === "recordCreated") {
                        a[key] = new Date(a[key]);
                    }
                }
            });
            this.setState({ vehicleHistoryData: result });
            this.setState({ isLoading: false, isHidden: true });
            this.refs.getDataBtn.removeAttribute("disabled");
        });
    }

    render() {

        const ChildLoader = () => (
            <div className="loader"></div>
        )

        return (
            <div style={{ height: '100%' }}>

                <table style={{ width: '85%' }}>
                    <tbody>
                        <tr>
                            <td style={{ width: '10%' }}>
                                <label> Connection String: </label>
                            </td>
                            <td style={{ width: '30%' }}>
                                <DropDownList data={this.state.connectionStringList} dataItemKey="value" textField="key" value={this.state.selectedConnectionString} onChange={this.handleChange} style={{ width: '95%' }} />
                            </td>
                            <td>
                                <input type="text" value={this.state.selectedConnectionString.value} style={{ width: '100%' }} onChange={this.handleCustomConnectionChange} onBlur={this.updateCustomConnectionString} />
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table style={{ width: '65%', marginTop : '1%' }}>
                    <tbody>
                        <tr >
                            <td>
                                <p style={{ verticalAlign: 'center', textAlign: 'center' }}>Start Date(UTC):</p>
                            </td>
                            <td>
                                <DateTimePicker value={this.state.selectedFromDate} onChange={this.handleFromDateChnage} className="datePicker" />
                            </td>
                            <td>
                                <p style={{ verticalAlign: 'center', textAlign: 'center' }}>End Date(UTC):</p>

                            </td>
                            <td>
                                <DateTimePicker value={this.state.selectedEndDate} onChange={this.handleTODateChnage} className="datePicker" />
                            </td>
                            <td>
                                <p style={{ verticalAlign: 'center', textAlign: 'center' }}>Vehicle ID :</p>
                            </td>
                            <td>
                                <DropDownList data={this.state.vehicleIDList} value={this.state.selectedVehicleID} onChange={this.handleVehcleChnage} className="datePicker" />
                            </td>
                            <td>
                                <button className="btn btn-primary datePicker" onClick={this.getData} ref="getDataBtn" >Load Data</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <PageWrapper title="Vehicle History" className="view">
                    <SMIGrid data={this.state.vehicleHistoryData} ref="grid" dataName="view" selectable={false} pageable={true} itemsPerPage={250}>
                        <Column field="vehicleID" title="Vehicle ID" editable={false} />
                        <Column field="fixTaken" title="Fix Taken" editable={false} filterCell={GridCustomFilters.DateTimeRange(this.shortDateFormat, this.shortTimeFormat)} customTemplate={GridCustomTemplates.DateTime(this.shortDateFormat, this.shortTimeFormat)}  />
                        <Column field="lat" title="Latitude" editable={false} />
                        <Column field="lng" title="Longitude" editable={false} />
                        <Column field="velocity" title="Velocity" editable={false} />
                        <Column field="bearing" title="Bearing" editable={false} />
                        <Column field="distanceTravelled" title="Distance" editable={false} />
                        <Column field="recordCreated" title="Record Created" editable={false} filterCell={GridCustomFilters.DateTimeRange(this.shortDateFormat, this.shortTimeFormat)} customTemplate={GridCustomTemplates.DateTime(this.shortDateFormat, this.shortTimeFormat)} />
                    </SMIGrid>
                </PageWrapper>
                {!this.state.isHidden && <ChildLoader />}
            </div>
        );
    }


} 