import { formatDate } from '@telerik/kendo-intl';

export function addDaysToDate(date, days) {
    var result = new Date(date);
    result.setDate(date.getDate() + days);
    return result;
}

export function adjustDateTimeStringWithTimeZone(source) {
    var res = null;
    try {
        var d = new Date(source);
        var tzo = d.getTimezoneOffset() / 60;

        var s = new Date(source);
        s.setHours(s.getHours() - tzo);
        res = s.toISOString();
    }
    catch (err) {
        console.log("Invalid schedule time.")
    }
    finally {
        return res;
    }
}

export function parseDateToUserString(time, date) {
    time = new Date(time.replace('T', ' '));
    date = new Date(date.replace('T', ' '));
    var stringDate = "";
    var hours = time.getHours();
    var minutes = time.getMinutes();
    if (time.getDate() !== date.getDate()) {
        stringDate = (hours < 10 ? "0" : "") + hours + ":" + (minutes < 10 ? "0" : "") + minutes + " XM";
    } else if (hours === 12) {
        stringDate = 12 + ":" + (minutes < 10 ? "0" : "") + minutes + " PM";
    } else if (hours >= 12) {
        stringDate = (hours < 22 ? "0" : "") + (hours - 12) + ":" + (minutes < 10 ? "0" : "") + minutes + " PM";
    } else if (hours === 0) {
        stringDate = 12 + ":" + (minutes < 10 ? "0" : "") + minutes + " AM";
    } else {
        stringDate = (hours < 10 ? "0" : "") + hours + ":" + (minutes < 10 ? "0" : "") + minutes + " AM";
    }
    return stringDate;
}

export function parseDateToFormattedString(time, date, format) {
    var stringDate = formatDate(time, format)
    var timeDay = time.getDate()
    var baseDay = date.getDate()

    if (timeDay !== baseDay) {
        //don't worry about "HH" case padding 0, XM will always have full digits.
        if (format.indexOf("H") !== -1) {
            //replace the hours part (first part before ":")
            var hoursPart = stringDate.substring(0, stringDate.indexOf(":"));
            var hoursNum = parseInt(hoursPart)
            //add 24 to the hours part
            hoursNum = hoursNum + 24
            stringDate = hoursNum + stringDate.substring(stringDate.indexOf(":"), stringDate.length)
        }
        else if (format.indexOf("t") !== -1) {
            //format the XM part
            stringDate = (stringDate.indexOf("AM") !== -1) ? stringDate.replace("AM", "XM") : stringDate.replace("PM", "XM")
        }
    }

    return stringDate;
}

export function parseDateToFormattedShortString(time, timeFormat, dateFormat) {
    var stringTimeDate = formatDate(time, timeFormat)
    var stringDate = formatDate(time, dateFormat)
    return stringDate + ' ' + stringTimeDate;
}

export function shortFormatXM36hour(date, format) {
    //day = 1 for today, day = 2 for xm
    //'HH' or 'H' converts into 36 hours time, pad 0 if necessary
    //'tt' converts AM/PM into XM
    //custom convert if date is next day
    //thats it.
    if (!date) return '';
    var stringDate = formatDate(date, format)
    if (date.getDate() > 1) {
        if (format.indexOf("H") !== -1) {
            //replace the hours part (first part before ":")
            var hoursPart = stringDate.substring(0, stringDate.indexOf(":"));
            var hoursNum = parseInt(hoursPart)
            //add 24 to the hours part
            hoursNum = hoursNum + 24
            stringDate = hoursNum + stringDate.substring(stringDate.indexOf(":"), stringDate.length)
        }
        else if (format.indexOf("t") !== -1) {
            //format the XM part
            stringDate = (stringDate.indexOf("AM") !== -1) ? stringDate.replace("AM", "XM") : stringDate.replace("PM", "XM")
        }
    }
    return stringDate;
}

export function getTodayDate() {
    var today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
}

export function isSameDate(date1, date2) {
    var d1 = new Date(date1), d2 = new Date(date2);
    d1.setHours(0, 0, 0, 0);
    d2.setHours(0, 0, 0, 0);
    return d1.getTime() === d2.getTime();
}

export function dateToImpreciseISOStr(date) {
    return dateToTimelessISO(date) + 'T' + dateToTimeOnlyISO(date);
}

export function isImpreciseISOStr(str) {
    return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?$/.test(str);
}

export function getMinutesBetweenDates(date1, date2) {
    if (!date1 || !date2) return 0;
    var diff = date2.getTime() - date1.getTime();
    return (diff / 60000);
}

export function dateToTimelessISO(date) {
    var year = date.getFullYear().toString();
    var month = (date.getMonth() + 1).toString();
    if (month.length < 2) month = '0' + month;
    var day = date.getDate().toString();
    if (day.length < 2) day = '0' + day;
    return year + '-' + month + '-' + day;
}

export function dateToTimeOnlyISO(date) {
    var hour = date.getHours().toString();
    if (hour.length < 2) hour = '0' + hour;
    var minute = date.getMinutes().toString();
    if (minute.length < 2) minute = '0' + minute;
    var second = date.getSeconds().toString();
    if (second.length < 2) second = '0' + second;
    return hour + ':' + minute + ':' + second;
}

/**
* fromISO assume C# serialize client time in pretented utc format before serialization. It will parse into browser time, to display correct client time.
* The result tick is no longer equal to the same moment.
* @param s string 
*/
export function getDateFromISO(s) {
    if (typeof s === 'object') return s;
    var day, tz,
        rx = /^(\d{4}-\d\d-\d\d([tT ][\d:.]*)?)([zZ]|([+-])(\d\d):(\d\d))?$/,
        p = rx.exec(s) || [];
    if (p[1]) {
        day = p[1].split(/\D/);
        for (var i = 0, L = day.length; i < L; i++) {
            day[i] = parseInt(day[i], 10) || 0;
        }
        day[1] -= 1;
        day = new Date(day[0], day[1], day[2], day[3], day[4], day[5], 0);
        if (!day.getDate()) return NaN;
        if (p[5]) {
            tz = (parseInt(p[5], 10) * 60);
            if (p[6]) tz += parseInt(p[6], 10);
            if (p[4] === '+') tz *= -1;
            if (tz) day.setUTCMinutes(day.getUTCMinutes() + tz);
        }
        return day;
    }
    return NaN;
};