import React, { Component } from 'react';
import { Layout } from './components/Layout';
import { ViewVehicleHistory } from './components/ViewVehicleHistory';

import './custom.css'

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <div className="container">
                    <div style={{ display: 'flex', flexDirection: "column", height: '85vh' }}>
                        <ViewVehicleHistory />
                    </div>
                </div>
            </Layout>
        );
    }
}
