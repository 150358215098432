import React from 'react';
import ReactDOM from 'react-dom';

//Component to display loading mask when grid is loading/saving data
export default () => {
    const loadingPanel = (
        <div className="k-loading-mask">
            <span className="k-loading-text">Loading...</span>
            <div className="k-loading-image"></div>
            <div className="k-loading-color"></div>
        </div>
    );

    const gridContent = document && document.querySelector('.k-grid-content');
    return gridContent ? ReactDOM.createPortal(loadingPanel, gridContent) : loadingPanel;
}