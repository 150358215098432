import React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import SMIGridSearch from './SMIGridSearch';
import LoadingPanel from './LoadingPanel';
import SMIColumnMenu from './SMIColumnMenu';

export default (props) => {
    const gridProps = Object.assign({}, props.gridProps);
    gridProps.data = props.data;
    gridProps.onDataStateChange = props.onDataStateChange;
    if (gridProps.editable !== false) {
        gridProps.onItemChange = props.onItemChange;
        gridProps.editField = "inEdit";
        gridProps.cellRender = props.renderers.cellRender;
        gridProps.rowRender = props.renderers.rowRender;
    }
    else delete gridProps.onItemChange;

    if (gridProps.groupable) {
        gridProps.expandField = '_expanded';
    }
    else delete gridProps.onExpandChange;

    if (gridProps.selectMode === 'single' || gridProps.selectMode === 'multiple') {
        gridProps.selectedField = '_isSelected';
        if (gridProps.selectMode === 'multiple') {
            gridProps.onRowClick = (event) => {
                let last = this.lastSelectedIndex;
                const current = gridProps.data.data.findIndex(dataItem => dataItem === event.dataItem);

                if (!event.nativeEvent.shiftKey || (!last && last !== 0)) {
                    this.lastSelectedIndex = last = current;
                }

                let selectedItems = props.selectedItems;
                if (!event.nativeEvent.ctrlKey) {
                    this.state.data.forEach(item => item.selected = false);
                    selectedItems = [];
                }

                for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
                    selectedItems.push(gridProps.data.data[i]);
                }

                if (props.onSelectChange) props.onSelectChange(selectedItems);
            }
        }
        else {
            gridProps.onRowClick = (event) => {
                if (props.onSelectChange) props.onSelectChange(event.dataItem);
            }
        }
    }

    const addLabel = 'Add New ' + (props.dataName || '');

    //Filter out hidden columns
    let children = props.children.filter((c) => {
        if (!c) return false;
        if (c.props.field) {
            const columnMenuEntry = props.columns.filter((col) => col.field === c.props.field);
            return columnMenuEntry.length === 0 || columnMenuEntry[0].show;
        }
        return true;
    });

    if (gridProps.showColumnMenu) {
        children = children.map((c, i) => {
            //Don't add column menu to columns with fields starting with "_" as these are action columns (e.g. the delete button)
            if (c.props.field && c.props.field.indexOf('_') !== 0) {
                const copyProps = Object.assign({}, c.props);
                let width;
                if (copyProps.width) {
                    width = copyProps.width;
                    delete copyProps.width;
                }
                //If at least one column in the grid has a width prop (e.g. the delete button column) then all columns must have a width prop, even if it's just width={undefined} or columns can appear squished when they're hidden and then shown using the column menu
                return <Column {...copyProps} key={i} width={width} columnMenu={(props) => <SMIColumnMenu {...props} columns={this.state.columns} onColumnsSubmit={this.onColumnsSubmit} />} />
            }
            return c;
        });
    }

    return (
        <div onKeyDown={props.onKeyDown}>
            <div className="grid-toolbar silverlight-button-zone">
                <Button onClick={props.onAdd} className="k-add-new"><span className="k-icon k-i-plus"></span> {addLabel}</Button>
                <Button onClick={props.onSave} className="k-save"><span className="k-icon k-i-check"></span> Save</Button>
                <Button onClick={props.onCancelChanges} className="k-cancel"><span className="k-icon k-i-cancel"></span> Cancel Changes</Button>
                {props.allowExcelExport && <Button onClick={props.onExcelExport} className="k-export"><span className="k-icon k-i-custom"></span> Export to Excel</Button>}
                {gridProps.toolbarButtons}
                {props.showSavedMessage && <span className="inline-success" style={{ display: 'inline' }}>Saved</span>}
                {props.searchable && <SMIGridSearch onSubmit={props.onSearch} />}
            </div>
            <Grid {...gridProps} {...props.dataState} ref={props.gridRef}>
                {children}
            </Grid>
            {props.isLoading && <LoadingPanel />}
            <ExcelExport ref={props.excelExportRef} />
        </div>
    )
}