import React from 'react';

import './PageWrapper.scss';

export default (props) => {
    let cssClass = 'title ' + (props.className && props.className.length > 0 ? props.className : "maintain");

    let scheduleLabel = null;
    let pastScheduleLabel = null;
    if (props.isSchedulePage) {
        if (props.scheduleLabel) scheduleLabel = <span className="current-schedule">{props.scheduleLabel}</span>
        else scheduleLabel = <span className="current-schedule">No Schedule Selected</span>
        if (props.pastIsReadOnly && props.isPastSchedule) pastScheduleLabel = <span className="readonly-message">Read-Only Past Schedule</span>
    }

    return (
        <div className={"page-wrapper " + props.className}>
            <div className="title-holder">
                <h1 className={cssClass}>
                    {props.title}
                    {scheduleLabel}
                    {pastScheduleLabel}
                </h1>
                <div className="content-holder">
                    {props.children}
                </div>
            </div>
        </div>
    );
}