import React, { useState, useCallback } from 'react';

export default (props) => {

    const [searchQuery, setSearchQuery] = useState('');

    const onSubmit = props.onSubmit;

    const submitSearchQuery = useCallback((e) => {
        e.preventDefault();
        if (onSubmit) onSubmit(searchQuery);
        return false;
    }, [onSubmit, searchQuery]);

    const clearSearchQuery = useCallback(() => {
        setSearchQuery('');
        if (onSubmit) onSubmit('');
        return false;
    }, [onSubmit]);

    return (
        <form className="search" onSubmit={this.submitSearchQuery} >
            <input type="text" className="search-input" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
            <button className="search-btn" title="Search" onClick={submitSearchQuery} />
            <button className="clear-btn" title="Reset Search" onClick={clearSearchQuery} />
        </form>
    );
    
}