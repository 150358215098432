import React from 'react';
import { Tooltip } from '@progress/kendo-react-tooltip';

// Renderers class adapted from https://www.telerik.com/kendo-react-ui/components/grid/editing/editing-in-cell/
export default class SMIGridRenderers {
    constructor(enterEdit, exitEdit, itemChange, columns, rowColor, editFieldName, gridState) {
        this.enterEdit = enterEdit;
        this.exitEdit = exitEdit;
        this.editFieldName = editFieldName;
        this.itemChange = itemChange;
        this.rowColor = rowColor;
        this.columns = columns.filter(c => c && !!c.props.field);
        this.columnsByField = {};
        this.gridState = gridState;
        this.columns.forEach((c) => {
            if (c && c.props.field) this.columnsByField[c.props.field] = c;
        });
        this.cellRender = this.cellRender.bind(this);
        this.rowRender = this.rowRender.bind(this);
    }

    deleteRender(cellProps, column) {
        //If a disabled callback was passed, call it to determine if the delete button is disabled and what message to display if it is
        const disabledMessage = column.props.disabled && typeof column.props.disabled === 'function' ? column.props.disabled(cellProps.dataItem) : null;
        return <td><span title={disabledMessage || ''}><button tabIndex={-1} className={'k-button k-grid-delete' + (disabledMessage ? ' disabled' : '')} onClick={(e) => cellProps.onChange({ field: '_delete', dataItem: cellProps.dataItem })} /></span></td>;
    }

    actionColumnRender(cellProps, column) {

        const disabledMessage = column.props.disabled && typeof column.props.disabled === 'function' ? column.props.disabled(cellProps.dataItem) : null;
        const visible = column.props.visible && typeof column.props.visible === 'function' ? column.props.visible(cellProps.dataItem) : (column.props.visible || true);
        const text = column.props.value && typeof column.props.value === 'function' ? column.props.value(cellProps.dataItem) : (column.props.value || null);
        const width = column.props.width;

        const classNames = (column.props.className ? column.props.className : '')
            + (disabledMessage ? ' disabled' : '')
            + (!JSON.parse(visible) ? ' hide' : '');

        return <td style={{ width: width }}><span title={disabledMessage || column.props.title || ''}>
            <button tabIndex={-2} className={'k-button ' + classNames} onClick={(e) => column.props.onClick ? column.props.onClick(e, cellProps.dataItem) : null } >
                {text}
            </button>
        </span> </td>;
    }

    cellRender(tdElement, cellProps) {
        if (!tdElement) return null;
        const dataItem = cellProps.dataItem;
        const field = cellProps.field;
        let column = null;
        if (cellProps.field && this.columnsByField[cellProps.field]) column = this.columnsByField[cellProps.field];

        if (!column) {
            return tdElement;
        }

        if (field === '_delete') {
            //This column is a delete button, render it with the delete renderer
            return this.deleteRender(cellProps, column);
        }

        if (field === '_actionColumn') {
            return this.actionColumnRender(cellProps, column);
        }

        let isNotApplicable = false;
        if (column && column.props.isNotApplicable) isNotApplicable = typeof column.props.isNotApplicable === 'function' ? column.props.isNotApplicable(dataItem) : column.props.isNotApplicable;

        let tooltip = null;
        const preventEnterEditResult = column.props.preventEnterEdit ? column.props.preventEnterEdit(dataItem) : null;
        const flaggedToEdit = cellProps.dataItem[this.editFieldName] && (cellProps.field === cellProps.dataItem[this.editFieldName]);
        let isEditing = false;
        if (flaggedToEdit && !isNotApplicable && !preventEnterEditResult) {
            this.currentEditingField = field;
            this.currentEditingItem = dataItem;
            isEditing = true;
        }
        if (isEditing && column && column.props.customEditor && typeof column.props.customEditor === 'function') {
            //Render custom editor if one was provided
            const CustomEditor = column.props.customEditor;
            const editProps = {
                title: dataItem.preventExitEditMessage,
                ref: (td) => {
                    if (tooltip) {
                        tooltip.targetElement = td;
                        tooltip.handleMouseOver({ target: td, persist: () => { } });
                        setTimeout(() => tooltip && tooltip.handleMouseLeave({ target: td, persist: () => { } }), 2000);
                    }
                }
            };
            let editorChildren = [<CustomEditor key={1} field={cellProps.field} dataItem={cellProps.dataItem} onChange={(e) => { this.itemChange(e); }} exitEdit={this.exitEdit} />];
            //dataItem.preventExitEditMessage is set in the exitEdit() method in SMIGrid.jsx, and is generated by the preventExitEdit prop passed to the column
            if (dataItem.preventExitEditMessage) editorChildren.push(<Tooltip key={2} ref={(el) => tooltip = el} anchorElement="target" openDelay={0} content={(props) => <span>{dataItem.preventExitEditMessage}</span>} />);
            return React.cloneElement(tdElement, editProps, editorChildren);
        }
        let additionalProps;
        let children = tdElement.props.children ? tdElement.props.children : [];
        if (isNotApplicable) {
            children = [<span key={1} className="blocked-cell">Not Applicable</span>];
        }
        else if (!isEditing && column && column.props.customTemplate && typeof column.props.customTemplate === 'function') {
            //Use custom template function to render cell content
            children = [<span key={1}>{column.props.customTemplate(cellProps.dataItem, cellProps.field, this.gridState.data)}</span>];
        }
        else if (!isEditing) {
            children = [cellProps.dataItem[cellProps.field].toISOString ? cellProps.dataItem[cellProps.field].toISOString() : cellProps.dataItem[cellProps.field].toString()];
        }
        if (isEditing) additionalProps = {
            ref: (td) => {
                //Exit editing mode when the input element is blurred
                const input = td && td.querySelector('input');
                if (!input || (input === document.activeElement)) { return; }
                input.focus();
                input.addEventListener("blur", this.exitEdit);
            }
        };
        else additionalProps = {
            onClick: (e) => { if (!isNotApplicable && !!column.props.editable) this.enterEdit(dataItem, field); },
            ref: (td) => {
                if (td) {
                    if (!isNotApplicable) {
                        //Mark dirty cells or cells that have failed validation with their appropriate classes
                        if (cellProps.dataItem.dirtyFields && cellProps.dataItem.dirtyFields[field]) td.classList.add('dirty');
                        else td.classList.remove('dirty');
                        if (cellProps.dataItem.errorFields && cellProps.dataItem.errorFields[field]) {
                            td.classList.add('error');
                        }
                        else td.classList.remove('error');
                    }
                    else {
                        //Not applicable cells should not be marked dirty or error
                        td.classList.remove('dirty');
                        td.classList.remove('error');
                    }
                    if (tooltip) {
                        tooltip.targetElement = td;
                        tooltip.handleMouseOver({ target: td, persist: () => { } });
                        // Have tooltip disappear after 4 seconds to prevent it from blocking access to other cells
                        setTimeout(() => tooltip && tooltip.handleMouseOut({ clientX: tooltip.left, clientY: tooltip.top }), 4000);
                    }
                }
            }
        };

        //If preventEnterEdit() returned a string, we'll display that string as a tooltip message
        const preventEnterEditMessage = typeof preventEnterEditResult === 'string' ? preventEnterEditResult : null;

        if (cellProps.dataItem.errorFields && cellProps.dataItem.errorFields[field] && typeof cellProps.dataItem.errorFields[field] === 'string' && !isNotApplicable && !isEditing) {
            //Add tooltip to field that failed validation on save
            const errorMessage = cellProps.dataItem.errorFields[field];
            children.push(<Tooltip key={2} ref={(el) => tooltip = el} anchorElement="target" openDelay={0} content={(props) => <span>{errorMessage}</span>} />);
            additionalProps['title'] = errorMessage;
        }
        else if (flaggedToEdit && preventEnterEditMessage) {
            //Add tooltip to field the user clicked but cannot edit due to some condition specified in the preventEnterEdit prop
            children.push(<Tooltip key={2} ref={(el) => tooltip = el} anchorElement="target" openDelay={0} content={(props) => <span>{preventEnterEditMessage}</span>} />);
            additionalProps['title'] = preventEnterEditMessage;
        }
        
        return React.cloneElement(tdElement, { ...tdElement.props, ...additionalProps }, children);
    }

    rowRender(trElement, rowData) {
        const trProps = {
            ...trElement.props,
            onKeyDown: (event) => {
                if (event.keyCode === 13) {
                    //Enter key pressed, stop editing
                    this.exitEdit();
                }
            },
            className: trElement.props.className + (rowData.dataItem.inEdit ? ' k-grid-edit-row' : '')
        };
        if (this.rowColor) {
            if (typeof this.rowColor === 'function') trProps.style = { backgroundColor: this.rowColor(rowData.dataItem) };
            else trProps.style = { backgroundColor: this.rowColor };
        }
        else trProps.style = {};
        return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
    }
}