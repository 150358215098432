import React, { Component } from 'react';
import SMIGridPresentation from './SMIGridPresentation';
import { process } from '@progress/kendo-data-query';
import SMIGridRenderers from './SMIGridRenderers';
import axios from 'axios';
import { isImpreciseISOStr, dateToImpreciseISOStr, getDateFromISO } from '../Utils/DateUtil';

import './SMIGrid.scss';

export default class SMIGrid extends Component {

    state = {
        data: this.props.data || [],
        showSavedMessage: false,
        filter: null,
        sort: null,
        columns: [],
        dataState: { sort: this.props.sort, group: this.props.group, skip: 0, take: this.props.pageable ? this.props.itemsPerPage : undefined},
        editField: undefined,
        selectedItems: [],
        pageNumber: 1,
        loading: false,
        result: null,
        searchQuery: ''
    };

    pristineData: [];
    deletedItems = [];
    columnsByField = {};
    dummyId = -1;
    expandedFields = {};
    gridRef = React.createRef();
    excelExportRef = React.createRef();

    constructor(props) {
        super(props);

        this.renderers = new SMIGridRenderers(this.enterEdit.bind(this), this.exitEdit.bind(this), this.itemChange.bind(this), props.children, props.rowColor, 'inEdit', this.state);
    }

    enterEdit(dataItem, field) {
        if (dataItem.inEdit && field === this.state.editField) {
            return;
        }
        if (!this.exitEdit()) return;
        dataItem.inEdit = field;
        this.setState({
            editField: field,
            data: this.state.data
        });
        const column = this.columnsByField[field];
        if (column && column.props.onEnterEdit) column.props.onEnterEdit(dataItem);
    }

    exitEdit() {
        if (this.state.editField) {
            //If the current column being edited has a preventExitEdit prop, call it to check if it's okay to allow the user to stop editing the cell
            const column = this.columnsByField[this.state.editField];
            if (column) {
                const editItemArr = this.state.data.filter((d) => !!d.inEdit);
                if (column.props.preventExitEdit) {
                    if (editItemArr.length > 0) {
                        editItemArr[0].preventExitEditMessage = column.props.preventExitEdit(editItemArr[0]);
                        if (editItemArr[0].preventExitEditMessage) {
                            this.forceUpdate();
                            return false;
                        }
                    }
                }
                if (column.props.onExitEdit && editItemArr.length > 0) column.props.onExitEdit(editItemArr[0]);
            }
        }
        this.state.data.forEach((d) => { d.inEdit = undefined; d.preventExitEditMessage = undefined; });
        this.setState({
            data: this.state.data,
            editField: undefined
        });
        return true;
    }

    deepCopy(aObject) {
        if (!aObject) {
            return aObject;
        }

        var bObject, v, k;
        bObject = Array.isArray(aObject) ? [] : {};
        for (k in aObject) {
            v = aObject[k];
            bObject[k] = (typeof v === "object" && !(v instanceof Date)) ? this.deepCopy(v) : v;
        }
        return bObject;
    }

    loadData = () => {
        this.setState({ loading: true });
        axios.get(this.props.crudUrl).then(response => {
            let data = response.data;
            this.convertDateStringsToDates(data);
            if (this.props.onDataLoaded) this.props.onDataLoaded(data);
            this.pristineData = this.deepCopy(data);
            this.setState({ data: data });
        })
        .catch(error => {
            console.log(error);
            alert('Error loading data');
        })
        .then(() => this.setState({ loading: false }));
    }

    getDummyId() {
        this.dummyId--;
        return this.dummyId;
    }

    flashSavedMessage = () => {
        this.setState({ showSavedMessage: true });
        setTimeout(() => this.setState({ showSavedMessage: false }), 4000);
    }

    saveData = () => {
        this.exitEdit();
        if (this.validate()) {
            //Need to make two requests. One to save new/updated data items, and one to delete data items
            const dirtyData = this.state.data.filter(d => d.dirty).map(d => Object.assign({}, d));
            this.convertDatesToStrings(dirtyData);
            if (dirtyData.length > 0 || this.deletedItems.length > 0) {
                const saveRequest = dirtyData.length > 0 ? axios.post(this.props.postUrl || this.props.crudUrl, dirtyData) : Promise.resolve();
                const deleteRequest = this.deletedItems.length > 0 ? axios.delete(this.props.deleteUrl || this.props.crudUrl, { data: this.deletedItems }).then(response => this.deletedItems = []) : Promise.resolve();

                this.setState({ loading: true });
                Promise.all([saveRequest, deleteRequest]).then(() => {
                    this.loadData();
                    this.deletedItems = [];
                    this.setPageDirtyValue(false);
                    this.flashSavedMessage();
                    if (this.props.onSaveComplete && typeof this.props.onSaveComplete === 'function') this.props.onSaveComplete();
                }).catch(error => {
                    console.log(error);
                    alert('Unable to save due to error');
                })
                .then(() => this.setState({ loading: false }));
            }
        }
        else this.setState({ data: this.state.data });
    }

    cancelChanges = () => {
        this.deletedItems = [];
        this.setState({ data: this.deepCopy(this.pristineData) });
        this.setPageDirtyValue(false);
    }

    getColumnListForColumnMenu() {
        let columns = [];
        this.props.children.forEach((c) => {
            if (!c) return;
            //Exclude columns with fields starting with "_" from the column menu as these are action columns (e.g. the delete button)
            if (c.props.field && c.props.field.indexOf('_') !== 0)
                columns.push({ field: c.props.field, title: c.props.title, show: !c.props.hidden})
        });
        return columns;
    }

    componentDidMount() {
        if (this.props.crudUrl) this.loadData();
        else if (this.props.data) this.pristineData = this.deepCopy(this.props.data);
        this.columnsByField = this.getColumnsByField();
        this.setState({ columns: this.getColumnListForColumnMenu() });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.crudUrl !== this.props.crudUrl) {
            if (this.props.crudUrl) this.loadData();
            else this.setState({ data: [] });
        }
        //Update columns and renderers incase column props changed
        let columnsChanged = false;
        if (this.props.children.length !== prevProps.children.length) columnsChanged = true;
        else {
            for (let i = 0; i < this.props.children.length; i++) {
                if (this.props.children[i] !== prevProps.children[i]) {
                    columnsChanged = true;
                    break;
                }
            }
        }
        if (columnsChanged) {
            this.columnsByField = this.getColumnsByField();
            this.renderers = new SMIGridRenderers(this.enterEdit.bind(this), this.exitEdit.bind(this), this.itemChange.bind(this), this.props.children, this.props.rowColor, 'inEdit', this.state);
            this.setState({ columns: this.getColumnListForColumnMenu() });
        }
        if (prevProps.data !== this.props.data) {
            this.pristineData = this.deepCopy(this.props.data);
            this.setState({ data: this.props.data });
        }
        if (prevState.data !== this.state.data || prevState.searchQuery !== this.state.searchQuery) {
            this.updateDataResult(this.state.dataState);
        }
        if (prevProps.itemsPerPage !== this.props.itemsPerPage || prevProps.pageable !== this.props.pageable) {
            const newDataState = Object.assign({}, this.state.dataState);
            newDataState.take = this.props.pageable ? this.props.itemsPerPage : undefined;
            newDataState.skip = 0;
            this.setState({ pageNumber: 1, dataState: newDataState });
            this.updateDataResult(newDataState);
        }
    }

    getColumnsByField() {
        let columnsByField = {};
        this.props.children.forEach((c) => {
            if (!c) return;
            //Exclude columns with fields starting with "_" from the column menu as these are action columns (e.g. the delete button)
            if (c.props.field) columnsByField[c.props.field] = c;
        });
        return columnsByField;
    }

    getDefaultValues() {
        let defaults = {};
        this.props.children.filter(c => c && c.props.field && c.props.defaultValue !== undefined).forEach(c => defaults[c.props.field] = c.props.defaultValue);
        return defaults;
    }

    onColumnsSubmit = (columnsState) => {
        this.setState({
            columns: columnsState
        });
    }

    addNew = () => {
        const defaults = this.getDefaultValues();
        let newItem = Object.assign({}, defaults);
        if (this.props.idField) newItem[this.props.idField] = this.getDummyId();
        else newItem.id = this.getDummyId();
        let newData = this.state.data.slice(0);
        newData.unshift(newItem);
        this.setState({ data: newData });

        //Automatically start editing the first editable column in the grid for the new item
        const firstEditableField = this.getFirstEditableColumnField();
        setTimeout(() => this.enterEdit(newItem, firstEditableField), 10);
    }

    expandChange = (event) => {
        event.dataItem[event.target.props.expandField] = event.value;
        if (event.value) this.expandedFields[event.dataItem.value] = true;
        else delete this.expandedFields[event.dataItem.value];
        this.setState({ result: Object.assign({}, this.state.result), dataState: this.state.dataState });
    }

    convertDateStringsToDates(data) {
        data.forEach((d) => {
            for (let key in d) {
                if (isImpreciseISOStr(d[key])) d[key] = getDateFromISO(d[key]);
            }
        })
    }

    convertDatesToStrings(data) {
        data.forEach((d) => {
            for (let key in d) {
                if (d[key] instanceof Date) d[key] = dateToImpreciseISOStr(d[key]);
            }
        })
    }

    setPageDirtyValue(value) {
        if (!this.props.disableNavigationWarning && window.self !== window.top && window.parent && typeof window.parent.setChildPageDirty === 'function') {
            window.parent.setChildPageDirty(value);
        }
    }

    searchQuerySubmitted = (searchQuery) => {
        this.setState({ searchQuery: searchQuery });
    }

    validate() {
        let validationPassed = true;
        if (this.state.data) {
            for (let i = 0; i < this.props.children.length; i++) {
                if (this.props.children[i] && this.props.children[i].props.field && this.props.children[i].props.validationRules) {
                    const field = this.props.children[i].props.field;
                    this.props.children[i].props.validationRules.forEach((rule) => {
                        this.state.data.forEach((item) => {
                            const result = rule(item[field], item, field, this.state.data);
                            if (result !== true) {
                                //Validation failed. Mark this item as having an error
                                validationPassed = false;
                                if (!item.errorFields) item.errorFields = {};
                                if (!item.errorFields[field]) item.errorFields[field] = typeof result === 'string' ? result : true; //If validation function returned a string, store it in errorFields so it can be displayed as a tooltip
                            }
                        });
                    });
                }
            }
        }
        return validationPassed;
    }

    getFirstEditableColumnField() {
        for (let i = 0; i < this.props.children.length; i++) {
            if (this.props.children[i].props.editable && this.props.children[i].props.field && !this.props.children[i].props.hidden)
                return this.props.children[i].props.field;
        }
        return null;
    }

    itemChange = (event) => {
        if (event.field === '_delete') {
            //Delete button was pressed, delete the item
            this.deletedItems.push(event.dataItem);
            for (let i = 0; i < this.state.data.length; i++) {
                if (this.state.data[i] === event.dataItem) {
                    this.state.data.splice(i, 1);
                    break;
                }
            }
            this.setPageDirtyValue(true);
        }
        else if (event.dataItem[event.field] !== event.value) {
            //If the new value in the change event is different from the current value, change the field value to the new value
            this.setValue(event.dataItem, event.field, event.value);
        }
        //Update state so that grid cells will refresh
        this.setState({ data: this.state.data });
    }

    setValue = (dataItem, field, value) => {
        const column = this.columnsByField[field];
        const oldValue = dataItem[field];
        dataItem[field] = value;
        dataItem.dirty = true;
        if (!dataItem.dirtyFields) dataItem.dirtyFields = {};
        dataItem.dirtyFields[field] = true;
        this.setPageDirtyValue(true);
        //Clear any errors and then validate again on next save
        dataItem.errorFields = [];
        dataItem.errorMessages = {};
        if (column && column.props.onChange) column.props.onChange(dataItem, field, oldValue, value, this.setValue.bind(this));
        
    }

    navigateOnTab = (e) => {
        if (e.keyCode === 9) {
            //Tab key pressed, switch to editing the next cell over
            let editingItem = null;
            for (let i = 0; i < this.state.data.length; i++) {
                if (this.state.data[i].inEdit) {
                    editingItem = this.state.data[i];
                    break;
                }
            }
            if (editingItem) {
                e.preventDefault();
                const columns = this.props.children.filter(c => c && !!c.props.field);
                let currentColumnIndex = -1;
                for (let i = 0; i < columns.length; i++) {
                    if (columns[i].props.field === editingItem.inEdit) {
                        currentColumnIndex = i;
                        break;
                    }
                }
                if (currentColumnIndex !== -1 && columns.length > currentColumnIndex + 1)
                    this.enterEdit(editingItem, columns[currentColumnIndex + 1].props.field);
                else
                    this.exitEdit();
            }
            return false;
        }
    }

    export = () => {
        const data = this.deepCopy(process(this.state.data, this.state.dataState).data);
        //Apply templates to data so that they will appear in the exported excel file the same way they appear in the grid
        data.forEach((d) => {
            for (let field in this.columnsByField) {
                const template = this.columnsByField[field].props.customTemplate;
                if (template && typeof template === 'function') d[field] = template(d, field);
            }
        });
        const excelColumns = this.gridRef.current.columns.filter(c => c.field.indexOf('_') !== 0);
        this.excelExportRef.current.save(data, excelColumns);
    }

    pageChange = (event) => {
        this.setState({
            pageNumber: Math.floor(event.page.skip / this.props.itemsPerPage) + 1
        });
    }

    isGroup(item) {
        return item.aggregates && item.items;
    }

    performSearchFilter = (data, searchQuery) => {
        if (!searchQuery) return data;
        const searchQueryLower = searchQuery.toLowerCase();
        return data.filter(dataItem => {
            for (let field in this.columnsByField) {
                if (field.indexOf('_') === 0) continue; // Skip columns starting with _ since these are buttons.
                let dataValue = dataItem[field];
                if (this.columnsByField[field].props.customTemplate) {
                    dataValue = this.columnsByField[field].props.customTemplate(dataItem, field, data);
                }
                if (dataValue && dataValue.toString().toLowerCase().indexOf(searchQueryLower) !== -1) return true;
            }
            return false;
        });
    }

    updateDataResult = (dataState) => {
        let data = this.state.data;
        if (this.props.searchable) data = this.performSearchFilter(data, this.state.searchQuery);
        const result = process(data, dataState);
        result.data.forEach(item => {
            item._isSelected = this.state.selectedItems.indexOf(item) !== -1;

            //If this item is grouping, set its expanded state based on the previously saved value or the default value based on the collapseGroups prop
            if (this.isGroup(item) && item._expanded === undefined) {

                if (this.expandedFields[item.value]) item._expanded = true;
                else item._expanded = !this.props.collapseGroups;
            }
        });
        this.setState({ result: result, dataState: dataState });
    }

    dataStateChange = (event) => {
        this.updateDataResult(event.data);
    }

    selectChange = (selectedItems) => {
        if (this.props.onSelectChange) this.props.onSelectChange(selectedItems);
        this.setState({ selectedItems: Array.isArray(selectedItems) ? selectedItems : [selectedItems] });
    }

    render() {
        const gridProps = Object.assign({}, this.props);
        const children = this.props.children;
        delete gridProps.children;

        return (<SMIGridPresentation
            data={this.state.result}
            onItemChange={this.itemChange}
            onExpandChange={this.expandChange}
            onDataStateChange={this.dataStateChange}
            selectedItems={this.state.selectedItems}
            columns={this.state.columns}
            onAdd={this.addNew}
            onSave={this.saveData}
            onCancelChanges={this.cancelChanges}
            onSearch={this.searchQuerySubmitted}
            onExcelExport={this.export}
            isLoading={this.state.loading}
            showSavedMessage={this.state.showSavedMessage}
            dataState={this.state.dataState}
            excelExportRef={this.excelExportRef}
            gridRef={this.gridRef}
            gridProps={gridProps}
            renderers={this.renderers}
            onKeyDown={this.navigateOnTab} >
            {children}
        </SMIGridPresentation>)

    }
}

SMIGrid.defaultProps = {
    filterable: true,
    sortable: true,
    editable: true,
    groupable: false,
    showColumnMenu: false,
    searchable: false,
    resizable: true
}