import React from 'react';
import { formatDate } from '@telerik/kendo-intl';


export function DateTime(shortDateFormat, shortTimeFormat) {
    let dateFormat = ''
    if (shortTimeFormat) {
        dateFormat = shortDateFormat + ' ' + shortTimeFormat.replace('tt', 'a');
    }
    
    return function (dataItem, field) {
        const dataValue = dataItem[field];
        if (dataValue) return formatDate(dataValue, dateFormat);
        return '';
    }
}

export function Date(shortDateFormat) {
    return function (dataItem, field) {
        const dataValue = dataItem[field];
        if (dataValue) return formatDate(dataValue, `${shortDateFormat}`);
        return '';
    }
}

export function Color() {
    return function (dataItem, field) {
        const dataValue = dataItem[field];
        if (dataValue) return <span className="color-display" style={{ backgroundColor: dataValue }}>&nbsp;</span>
        return '';
    }
}
    //Used for fields where the value in the field is an ID but we need to display the associated string (e.g. the field value is the vehicle ID but we want to display the vehicle name)
export function Enum(data, dataValueField, dataTextField) {
    return function (dataItem, field) {
        const dataValue = dataItem[field];

        let listData = data;
        if (typeof data === 'function') listData = data(dataItem);

        if (!listData) return '';

        let searchedData = listData.filter((d) => d[dataValueField] === dataValue);
        if (searchedData.length > 0) return searchedData[0][dataTextField];
        return '';
    }
}

//Used for fields where the user can select multiple values and they're stored in a comma-separated string
export function CSV(data, dataValueField, dataTextField) {
    return function (dataItem, field) {
        const dataValue = dataItem[field];
        if (!dataValue) return '';
        let splitStr = dataValue.split(',').filter((s) => !!s);
        if (data && dataValueField && dataTextField) {
            //Lookup values
            splitStr = splitStr.map((val) => {
                for (let i = 0; i < data.length; i++) {
                    if (data[i][dataValueField] === val) return data[i][dataTextField];
                }
                return '';
            });
        }
        return splitStr.join(', ');
    }
}

export function YesNoBinary() {
    return function (dataItem, field) {
        const dataValue = dataItem[field];
        if (dataValue && dataValue === 1) return 'Yes';
        return 'No';
    }
}

